import { useContext, useEffect } from "react";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ThemeSettings from "./components/settings";
import { SocketContext } from "./contexts/SocketsContext";
import { useSelector } from "react-redux";

function App() {
  const { initializeSocket } = useContext(SocketContext);
  const currentUser = useSelector((state) => state.app.currentUser); // Get current user from Redux

  useEffect(() => {
    if (currentUser?.id) {
      // Initialize socket connection if user is logged in
      initializeSocket(currentUser.id);
    }
  }, [currentUser, initializeSocket]); // Re-run if currentUser or initializeSocket changes

  return (
    <ThemeProvider>
      <ThemeSettings>
        <Router />
      </ThemeSettings>
    </ThemeProvider>
  );
}

export default App;
