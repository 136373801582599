import { BounceLoader } from "react-spinners";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";

const LoadingScreen = () => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <BounceLoader
          color={theme.palette.primary.main}
          size={100}
          speedMultiplier={1}
        />
      </Box>
    </>
  );
};

export default LoadingScreen;
