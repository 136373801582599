import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/Images/logo.svg";
import { Nav_Buttons, Profile_Menu } from "../../data";
import useSettings from "../../hooks/useSettings";
import { logoutUser } from "../../redux/slices/app";
import SwitchTheme from "../../components/SwitchTheme";

const getMenuPath = (index) => {
  switch (index) {
    case 0:
      return "/profile";

    case 1:
      return "/settings";

    case 2:
      // todo - update token and set isAuth = false
      return "/auth/login";

    default:
      break;
  }
};

const SideBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const url = process.env.REACT_APP_API_URL;

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Use dispatch hook to dispatch logout action
  const { currentUser } = useSelector((state) => state.app); // Get current user from Redux
  const profileImage = `${url}/storage/${currentUser?.image}`;

  //switch themes
  const { onToggleMode } = useSettings();

  // Handle logout
  const handleLogout = () => {
    dispatch(logoutUser()); // Dispatch the logout action
    navigate("/auth/login"); // Navigate to login page after logout
  };
  return (
    <Box
      p={2}
      sx={{
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
        height: "100vh",
        width: 100,
        display: "flex",
      }}
    >
      <Stack
        direction="column"
        alignItems={"center"}
        justifyContent="space-between"
        sx={{ width: "100%", height: "100%" }}
        spacing={3}
      >
        <Stack alignItems={"center"} spacing={4}>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              height: 64,
              width: 64,
              borderRadius: 1.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={Logo} alt={"Logo icon"} width={50} height={50} />
          </Box>
          <Stack
            sx={{ width: "max-content" }}
            direction="column"
            alignItems="center"
            spacing={3}
          >
            {Nav_Buttons?.map((el, ind) => (
                <Tooltip placement="right" title={el?.title} kay={ind}>
                <NavLink
                  key={el.index}
                  to={el.path}
                  end
                  style={({ isActive }) => ({
                    borderRadius: "12px",
                    backgroundColor: isActive
                      ? theme.palette.primary.main
                      : "transparent",
                    display: "flex",
                  })}
                >
                  {({ isActive }) => (
                    <IconButton
                      sx={{
                        width: "max-content",
                        color: isActive
                          ? "#fff"
                          : theme.palette.mode === "light"
                          ? "#000"
                          : theme.palette.text.primary,
                      }}
                    >
                      {el.icon}
                    </IconButton>
                  )}
                </NavLink>
              </Tooltip>
            ))}
          </Stack>
        </Stack>

        <Stack spacing={4}>
          <SwitchTheme
            onChange={() => {
              onToggleMode();
            }}
            defaultChecked
          />
          <Avatar
            id="basic-button"
            sx={{ cursor: "pointer" }}
            src={profileImage}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Stack spacing={1} px={1}>
              {Profile_Menu?.map((el, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    if (index === Profile_Menu?.length - 1) {
                      handleLogout();
                    } else {
                      navigate(getMenuPath(index));
                    }
                    handleClose();
                  }}
                >
                  <Stack
                    sx={{ width: 100 }}
                    direction="row"
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <span>{el.title}</span>
                    {el.icon}
                  </Stack>
                </MenuItem>
              ))}
            </Stack>
          </Menu>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SideBar;
