import { createSlice } from "@reduxjs/toolkit";
// define initial state
const initialState = {
  contacts: [],
};

// create slice
export const contacts = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setContacts(state, action) {
      state.contacts = action.payload;
    },
  },
});
export const { setContacts } = contacts.actions;
