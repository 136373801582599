import { createSlice } from "@reduxjs/toolkit";
// define initial state
const initialState = {
  sidebar: {
    open: false,
    type: "CONTACT", // can be CONTACT, STARRED,SHARED
  },
  currentUser: {},
};

// create slice
export const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    ToggleSidebar(state, action) {
      state.sidebar.open = !state.sidebar.open;
    },
    CloseSidebar(state, action) {
      state.sidebar.open = false;
      state.sidebar.type = "CONTACT";
    },
    UpdateProfilePicture(state, action) {
      state.currentUser = { ...state.currentUser, image: action.payload };
    },
    UpdateSidebarType(state, action) {
      state.sidebar.type = action.payload;
    },
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    logoutUser(state) {
      state.currentUser = {};
    },
  },
});
export const {
  ToggleSidebar,
  CloseSidebar,
  UpdateProfilePicture,
  UpdateSidebarType,
  setCurrentUser,
  logoutUser,
} = slice.actions;
