import React, { createContext, useState, useEffect } from "react";
import io from "socket.io-client";

export const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const { REACT_APP_API_URL } = process.env;
  const [socket, setSocket] = useState(null);

  // Function to initialize socket with dynamic user ID
  const initializeSocket = (userId) => {
    if (socket || !userId) return; // Prevent re-connection if socket already exists or userId is missing

    const newSocket = io(REACT_APP_API_URL, { query: { userId } });
    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log(`Socket connected with ID: ${newSocket.id} and user ID: ${userId}`);
    });

    newSocket.on("disconnect", () => {
      console.log("Socket disconnected");
    });
  };

  // Disconnect the socket on unmount
  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={{ socket, initializeSocket }}>
      {children}
    </SocketContext.Provider>
  );
};
